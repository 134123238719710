import React, { FC, useEffect, useState } from 'react';
import NextLink from 'next/link';
import {
  Notification,
  Link,
  Button,
  Heading,
  Field,
  Icon,
  yogoRedIcon,
  LoadingIndicator,
} from '@lululemon/ecom-pattern-library';
import cs from 'classnames';
import { useFormat } from 'helpers/hooks/useFormat';
import useValidate from 'helpers/hooks/useValidate';
import { AUTH } from 'helpers/utils/googleAnalyticsEvents';
import { useAnalytics } from 'hooks/useAnalytics';
import { useAccount } from 'frontastic';
import { SUCCESS_MESSAGE_TYPE } from 'frontastic/tastics/account/login';
import styles from './login.module.scss';
interface Props {
  onLogin?: () => void;
  errorMessages: {
    api: string;
    emailRequired: string;
    passwordRequired: string;
    emailInvalid: string;
    accountLocked: string;
    inactiveAccountMessage: string;
    inactiveBusinessUnitMessage: string;
  };
  successMessage?: { type: SUCCESS_MESSAGE_TYPE; message?: string };
}

const LoginForm: FC<Props> = ({ onLogin, errorMessages, successMessage }) => {
  //i18n messages
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { validateEmail } = useValidate();
  //account actions
  const { login } = useAccount();
  //Google Analytics
  const { trackEvent, EVENT_CATEGORY } = useAnalytics();

  const { accountLocked, inactiveAccountMessage, inactiveBusinessUnitMessage } = errorMessages;

  //login data
  const [data, setData] = useState({ email: '', password: '', rememberMe: false });

  //error
  const [error, setError] = useState('');
  const [validationErrors, setValidationErrors] = useState({
    email: '',
    password: '',
  });

  const [isValidate, setIsValidate] = useState(false);
  const [startValidation, setStartValidation] = useState(false);
  //success
  const [success, setSuccess] = useState('');

  //processing...
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (successMessage && successMessage.type) {
      if (successMessage.message) {
        setSuccess(successMessage.message);
      } else if (successMessage.type == SUCCESS_MESSAGE_TYPE.CHANGE_PASSWORD_NOTIFICATION) {
        const message = formatAccountMessage({
          id: 'password.reset.success.message',
          defaultMessage: 'Your password has been updated. Please sign in below to continue.',
        });
        setSuccess(message);
      }
    }
  }, []);

  const resetFeedback = () => {
    setError('');
    setValidationErrors({
      email: '',
      password: '',
    });
    setSuccess('');
  };

  const handleValidate = async (inputName: string, inputValue: string, ishandleChange: boolean) => {
    error && setError('');
    const validations = { ...validationErrors };
    if (inputName === 'email') {
      const validEmail = validateEmail(inputValue);

      if (!inputValue) {
        if (ishandleChange) {
          if (startValidation) {
            validations.email = errorMessages.emailRequired;
            setValidationErrors(validations);
          }
        } else {
          validations.email = errorMessages.emailRequired;
          setValidationErrors(validations);
          setStartValidation(true);
        }
      } else if (!validEmail) {
        if (ishandleChange) {
          if (startValidation) {
            validations.email = errorMessages.emailInvalid;
            setValidationErrors(validations);
          }
        } else {
          validations.email = errorMessages.emailInvalid;
          setStartValidation(true);
          setValidationErrors(validations);
        }
      } else if (validEmail) {
        if (!ishandleChange) {
          setStartValidation(true);
        }
        validations.email = '';
        setValidationErrors({ ...validationErrors, email: '' });
      }
      setIsValidate(validations.email == '' && validations.password == '' && data.password != '');
    }

    if (inputName === 'password') {
      const validations = { ...validationErrors };
      if (inputValue == '') {
        validations.password = errorMessages.passwordRequired;
        setValidationErrors(validations);
      } else {
        validations.password = '';
        setValidationErrors({ ...validationErrors, password: '' });
      }
      setIsValidate(validations.email == '' && validations.password == '' && inputValue != '' && data.email != '');
    }
  };

  //handle text input change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleValidate(e.target.name, e.target.value, true);
    setData({ ...data, [e.target.name]: e.target.value });
  };

  // handle text blur
  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleValidate(e.target.name, e.target.value, false);
  };

  // incorrect details message
  const authWrongMessage =
    errorMessages?.api || formatErrorMessage({ id: 'auth.wrong', defaultMessage: 'Wrong email address or password' });

  //login user
  const loginUser = async () => {
    setLoading(true);
    setSuccess('');
    try {
      const { res, error } = await login(data.email, data.password, data.rememberMe);

      if (res?.id) {
        onLogin?.();
        setLoading(false);
      } else {
        let errorMsg = '';
        if (error?.message === 'Error: Blocked') {
          errorMsg = accountLocked; // account is locked, due to multiple wrong password attempt; message
          trackEvent(EVENT_CATEGORY.COMPONENT_EVENT, AUTH.ACCOUNT_LOCKOUT);
        } else if (error?.message === 'Error: account_inactive') {
          errorMsg = inactiveAccountMessage;
        } else if (error?.message === 'Error: account_to_business_unit_association_not_found') {
          errorMsg = inactiveAccountMessage;
        } else if (error?.message === 'Error: business_unit_inactive') {
          errorMsg = inactiveBusinessUnitMessage;
        } else {
          errorMsg = authWrongMessage;
        }
        setError(errorMsg);
        trackEvent(EVENT_CATEGORY.ERROR, AUTH.LOGIN_API_ERROR(errorMsg));
        setLoading(false);
        return;
      }
    } catch (error) {
      setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
      setSuccess('');
      setLoading(false);
      trackEvent(EVENT_CATEGORY.ERROR, AUTH.LOGIN_API_ERROR(error));
    }
    trackEvent(EVENT_CATEGORY.APP_RESPONSE, AUTH.LOGIN_API_RESPONSE);
  };

  //form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    //processing starts

    // removes previous error message if any
    setError('');
    setSuccess('');
    const emailValid = validateEmail(data.email);

    if (!data.password || !data.email || !emailValid) {
      const validations = { ...validationErrors };

      if (!data.password) {
        validations.password = errorMessages.passwordRequired;
      }
      if (!data.email) {
        validations.email = errorMessages.emailRequired;
      } else if (!emailValid) {
        validations.email = errorMessages.emailInvalid;
      }
      setValidationErrors(validations);

      return;
    } else {
      //if user wants to login
      loginUser();
      //processing ends
      resetFeedback();
    }
  };

  const feedback = error || success;

  return (
    <>
      {loading && (
        <LoadingIndicator
          color={'red'}
          className={styles.loadingIndicator}
          data-testid={'login-form__loading-indicator_test-id'}
        />
      )}
      <div className="mb-36 md:mb-56 lg:mb-84">
        <div className="m-auto grid max-w-[540px] px-20">
          <div className="bg-white p-36">
            <Icon content={yogoRedIcon} className="mb-25 size-54" />

            <Heading className="lll-text-xsmall" tag="h1" showLineAccent data-testid="login-page__heading_test-id">
              Welcome to the Strategic Sales Site
            </Heading>

            <form onSubmit={handleSubmit} id="submit-login" className="py-20">
              <Notification
                data-testid="login-page__notification_test-id"
                visible={!!feedback.length}
                type={!!error.length ? 'error' : 'success'}
              >
                {feedback}
              </Notification>

              <Field
                id="email"
                type="email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={data.email}
                hint={validationErrors.email != '' ? validationErrors.email : undefined}
                status={validationErrors.email != '' ? 'error' : undefined}
                data-testid={'login-page__email-input_test-id'}
              >
                Email address
              </Field>
              <Field
                id="password"
                className={'mt-8'}
                name="password"
                type="password"
                onChange={handleChange}
                onBlur={handleBlur}
                allowShowPassword
                hint={validationErrors.password != '' ? validationErrors.password : undefined}
                status={validationErrors.password != '' ? 'error' : undefined}
                data-testid={'login-page__password-input_test-id'}
              >
                Password
              </Field>

              <div className="my-16 flex items-center justify-between p-0">
                <Link
                  className={cs(styles.forgotLink)}
                  variant="underline"
                  data-testid="login-page__forgot-your-password-link_test-id"
                  tag="div"
                >
                  <NextLink href={'/forgot-password'}>
                    {formatAccountMessage({ id: 'password.forgot', defaultMessage: 'Forgot your password?' })}
                  </NextLink>
                </Link>
              </div>

              <Button
                block
                kind="primary"
                type="submit"
                disabled={!isValidate}
                data-testid="login-page__sign-in-button_test-id"
              >
                {formatAccountMessage({ id: 'sign.in', defaultMessage: 'Sign in' })}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
